import Client from '../axios-client';

const resource = '/ai';

// Wspólne opcje dla requestów z plikami
const fileRequestOptions = {
  headers: { 'Content-Type': 'multipart/form-data' }
};

export default {
  recognizeInvoice(payload) {
    return Client.post(`${resource}/invoice-recognition`, payload, fileRequestOptions);
  }
}; 