import Client from '../axios-client';

const resource = '/application/application';

export default {
  addDocument(applicationId, payload) {
    return Client.post(`${resource}/${applicationId}/additional-documents`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },

  removeDocument(applicationId, documentId) {
    return Client.delete(`${resource}/${applicationId}/additional-documents/${documentId}`);
  },

  getDocuments(applicationId) {
    return Client.get(`${resource}/${applicationId}/additional-documents`);
  },

  downloadDocument(applicationId, documentId) {
    return Client.get(`${resource}/${applicationId}/additional-documents/${documentId}/download`, {
      responseType: 'blob'
    });
  },

  previewDocument(applicationId, documentId) {
    return Client.get(`${resource}/${applicationId}/additional-documents/${documentId}/preview`, {
      responseType: 'blob'
    });
  }
}; 