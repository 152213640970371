import AuthRepository from './core/auth-repository';
import UserAccountRepository from './core/user-account-repository';
import SubaccountRepository from './subaccount/subaccount-repository';
import SubaccountDashboardRepository from './subaccount/dashboard-repository';
import WalletRepository from "./subaccount/wallet-repository";
import WalletReportRepository from "./subaccount/wallet-report-repository";
import ImportRepository from "./import/import-repository";
import ImportOperationRepository from "./import/import-operation-repository";
import ImportSubaccountRepository from "./import/import-subaccount-repository";
import ApplicationReportRepository from "./application/report-repository";
import ApplicationApplicationRepository from "./application/application-repository";
import ApplicationSettlementRepository from "./application/settlement-repository";
import ApplicationBeneficiaryRepository from "./application/beneficiary-repository";
import MessageRepository from "./message/message-repository";
import MessageSearchSubaccountRecipient from "./message/search-recipient-repository";
import NotificationRepository from "./notification/notification-repository";
import FosterChildrenRepository from './subaccount/foster-children-repository';
import FraudCheckerRepository from './fraud-checker/fraud-checker-repository';
import AdditionalDocumentsRepository from './application/additional-documents-repository';
import AiRepository from './ai/ai-repository';

const repositories = {
    'AuthRepository': AuthRepository,
    'UserAccountRepository': UserAccountRepository,
    'SubaccountRepository': SubaccountRepository,
    'SubaccountDashboardRepository': SubaccountDashboardRepository,
    'WalletRepository': WalletRepository,
    'WalletReportRepository': WalletReportRepository,
    'ImportRepository': ImportRepository,
    'ImportOperationRepository': ImportOperationRepository,
    'ImportSubaccountRepository': ImportSubaccountRepository,
    'ApplicationReportRepository': ApplicationReportRepository,
    'ApplicationApplicationRepository': ApplicationApplicationRepository,
    'ApplicationSettlementRepository': ApplicationSettlementRepository,
    'ApplicationBeneficiaryRepository': ApplicationBeneficiaryRepository,
    'MessageRepository': MessageRepository,
    'MessageSearchSubaccountRecipient': MessageSearchSubaccountRecipient,
    'NotificationRepository': NotificationRepository,
    'FosterChildrenRepository': FosterChildrenRepository,
    'FraudCheckerRepository': FraudCheckerRepository,
    'AdditionalDocumentsRepository': AdditionalDocumentsRepository,
    'AiRepository': AiRepository
};

export default {
    get: name => repositories[name]
};
