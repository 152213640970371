import Client from '../axios-client';

const resource = '/application/application';

export default {
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  remove(applicationId) {
    return Client.delete(`${resource}/${applicationId}`);
  },
  changeNumber(applicationId, payload) {
    return Client.put(`${resource}/${applicationId}/change-number`, payload);
  },
  changeComment(applicationId, payload) {
    return Client.put(`${resource}/${applicationId}/change-comment`, payload);
  },
  addInvoiceToApplication(applicationId, payload) {
    return Client.post(`${resource}/${applicationId}/invoices`, payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  },
  removeInvoiceFromApplication(applicationId, invoiceId, version) {
    return Client.delete(`${resource}/${applicationId}/invoices/${invoiceId}?version=${version}`);
  },
  acceptInvoice(applicationId, invoiceId) {
    return Client.patch(`${resource}/${applicationId}/invoices/${invoiceId}/accept`);
  },
  rejectInvoice(applicationId, invoiceId, payload) {
    return Client.patch(`${resource}/${applicationId}/invoices/${invoiceId}/reject`, payload);
  },
  correctInvoiceAmount(applicationId, invoiceId, payload) {
    return Client.patch(`${resource}/${applicationId}/invoices/${invoiceId}/correct-amount`, payload);
  },
  changeInvoiceNumber(applicationId, invoiceId, payload) {
    return Client.patch(`${resource}/${applicationId}/invoices/${invoiceId}/change-number`, payload);
  },
  correctTripKilometers(applicationId, tripId, payload) {
    return Client.patch(`${resource}/${applicationId}/trips/${tripId}/correct-kilometers`, payload);
  },
  completeRoadCard(applicationId, payload) {
    return Client.put(`${resource}/${applicationId}/complete-roadcard`, payload);
  },
  changeApplicationStatus(applicationId, payload) {
    return Client.put(`${resource}/${applicationId}/change-status`, payload);
  },
  getItem(applicationId) {
    return Client.get(`${resource}/${applicationId}`);
  },
  getPDF: function (applicationId) {
    return Client.get(`${resource}/${applicationId}/pdf`,
      {
        responseType: 'blob',
        headers: { 'Accept': 'application/pdf' }
      }
    )},
  downloadInvoiceFile(applicationId, invoiceId) {
    return Client.get(`${resource}/${applicationId}/invoices/${invoiceId}/download`,
      {
        responseType: 'blob'
      }
    );
  },
  previewInvoiceFile(applicationId, invoiceId) {
    return Client.get(`${resource}/${applicationId}/invoices/${invoiceId}/preview`,  
      {
      responseType: 'blob'
      }
  );
  },
  addTripToApplication(applicationId, payload) {
    return Client.post(`${resource}/${applicationId}/trips`, payload,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  },
  removeTripFromApplication(applicationId, tripId) {
    return Client.delete(`${resource}/${applicationId}/trips/${tripId}`);
  },
  acceptTrip(applicationId, tripId) {
    return Client.patch(`${resource}/${applicationId}/trips/${tripId}/accept`);
  },
  rejectTrip(applicationId, tripId, payload) {
    return Client.patch(`${resource}/${applicationId}/trips/${tripId}/reject`, payload);
  },
  downloadTripFile(applicationId, tripId) {
    return Client.get(`${resource}/${applicationId}/trips/${tripId}/download`,
      {
        responseType: 'blob'
      }
    );
  },
  previewTripFile(applicationId, tripId) {
    return Client.get(`${resource}/${applicationId}/trips/${tripId}/preview`,
      {
        responseType: 'blob'
      }
    );
  },
};
